import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "seating-manager-api-beta"
    }}>{`Seating Manager API (beta)`}</h1>
    <p>{`Seating manager handles seating offers. A seating offer contains data for the seats in the material used on a dated service journey.
Each seat has a list of seat usages that hold reservation information for station pairs on the journey.`}</p>
    <h2 {...{
      "id": "seating-offer-creation"
    }}>{`Seating offer creation`}</h2>
    <p>{`A seating offer will be created when seating manager receives an event from scheduled stock containing information about a dated service journey and corresponding material.`}</p>
    <h2 {...{
      "id": "material"
    }}>{`Material`}</h2>
    <p>{`The events from scheduled stock contain links to material in rolling stock. Seating manager will fetch the material definitions from rolling stock.`}</p>
    <h2 {...{
      "id": "seating-offer-modifications"
    }}>{`Seating offer modifications`}</h2>
    <p>{`Events from scheduled stock may contain changes to a previously created seating offer. This will usually lead to the creation of a new version of the seating offer.`}</p>
    <h2 {...{
      "id": "seating-offer-deletions"
    }}>{`Seating offer deletions`}</h2>
    <p>{`Events from scheduled stock may contain information that a seating offer is no longer valid.
The seating offer will be marked as inactive. It will not be physically deleted.`}</p>
    <h2 {...{
      "id": "seat-reservations"
    }}>{`Seat reservations`}</h2>
    <p>{`Seating manager subscribes to order events. This will create reservations and update the seat usages of affected seats.`}</p>
    <h3 {...{
      "id": "first-version-of-a-new-reservation"
    }}>{`First version of a new reservation`}</h3>
    <p>{`The first version of a reservation is created in status DRAFT.
New reservations lines may be added. Corresponding seat usages will be updated.
Reservation lines may be deleted. Corresponding seat usages will be reset.`}</p>
    <p>{`When the reservation is confirmed, the status will be changed to CONFIRMED.`}</p>
    <h3 {...{
      "id": "new-version-of-a-confirmed-reservation"
    }}>{`New version of a confirmed reservation.`}</h3>
    <p>{`The new version will be created as a copy of the confirmed reservation in status DRAFT.
New reservations lines may be added. Corresponding seat usages will be updated.
Reservation lines may de deleted. Corresponding seat usages will be reset only if the reservation line was originally adde in the DRAFT reservation.`}</p>
    <p>{`When the reservation is confirmed, reservation lines no longer present, will reset seat usages.
The original reservation will be set to DEPRECATED.
The new reservation will be set to CONFIRMED.`}</p>
    <h3 {...{
      "id": "modification-of-a-confirmed-reservation"
    }}>{`Modification of a confirmed reservation`}</h3>
    <p>{`A change in a confirmed reservation, from for example a PUT Request, will result in a new draft version of the reservation.
The new draft reservation will only contain reservationLines defined in the request itself. All the new reservationLines
and old reservationLines with new seats will have new seatUsages.`}</p>
    <h3 {...{
      "id": "cancel-reservation"
    }}>{`Cancel reservation`}</h3>
    <h4 {...{
      "id": "if-the-reservation-is-draft-of-a-new-reservation"
    }}>{`If the reservation is DRAFT of a new reservation`}</h4>
    <p>{`Seat usages will be reset. The reservation lines will be deleted. The reservation will be set to CANCELLED.`}</p>
    <h4 {...{
      "id": "if-the-reservation-is-confirmed"
    }}>{`If the reservation is CONFIRMED`}</h4>
    <p>{`The reservation will be set to DEPRECATED. A new version without any reservation lines will be created and set to CANCELLED.
Seat usages will be reset.`}</p>
    <h4 {...{
      "id": "if-the-reservation-is-draft-of-a-confirmed-reservation"
    }}>{`If the reservation is DRAFT of a CONFIRMED reservation`}</h4>
    <p>{`The confirmed reservation will be set to DEPRECATED. The DRAFT reservation will be set to CANCELLED. All reservation lines in this reservation will be deleted.
Seat usages will be reset.`}</p>
    <h2 {...{
      "id": "rebooking"
    }}>{`Rebooking`}</h2>
    <p>{`Rebooking means that reservations in one seating offer are moved to another. This may be caused by modification of a seating offer that has led to the creation
of a new version.
Rebooking of a seat may lead to`}</p>
    <ul>
      <li parentName="ul">{`successful reservation with all properties satisfied`}</li>
      <li parentName="ul">{`partly successful reservation with important properties satisfied`}</li>
      <li parentName="ul">{`unsuccessful reservation`}</li>
    </ul>
    <h2 {...{
      "id": "sorting-when-rebooking"
    }}>{`Sorting when rebooking`}</h2>
    <p>{`The reservations to rebook should be ordered so that the most difficult cases are handled first.
This process is controlled by records in the table Sorting_Property.`}</p>
    <h3 {...{
      "id": "sorting-criteria-based-on-seating-properties"
    }}>{`Sorting criteria based on seating properties`}</h3>
    <p>{`This divides Netex properties into three groups that are compared separately.`}</p>
    <h4 {...{
      "id": "sorting-criterion---critical-seating-properties"
    }}>{`Sorting criterion - Critical seating properties`}</h4>
    <p>{`A seating property is introduced in this group by records like`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('NuisanceFacility.NO_ANIMALS', 3);`}</inlineCode></p>
    <p>{`The sorting value for this criterion is the number of matching properties in the reservation.`}</p>
    <h4 {...{
      "id": "sorting-criterion---important-seating-properties"
    }}>{`Sorting criterion - Important seating properties`}</h4>
    <p>{`A seating property is introduced in this group by records like`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('SeatPosition.UPPER_BERTH', 2);`}</inlineCode></p>
    <p>{`The sorting value for this criterion is the number of matching properties in the reservation.`}</p>
    <h4 {...{
      "id": "sorting-criterion---less-important-seating-properties"
    }}>{`Sorting criterion - Less important seating properties`}</h4>
    <p>{`A seating property is introduced in this group by records like`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('SeatPosition.WINDOW', 1);`}</inlineCode></p>
    <p>{`The sorting value for this criterion is the number of matching properties in the reservation.`}</p>
    <h3 {...{
      "id": "sorting-criteria-based-on-size-of-reservation"
    }}>{`Sorting criteria based on size of reservation`}</h3>
    <h4 {...{
      "id": "sorting-criterion---number-of-travellers"
    }}>{`Sorting criterion - Number of travellers`}</h4>
    <p>{`Reservations with a large number of travellers may be handled early in the process. The minimum size for this criterion to be used is defined by this record:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Travellers.MIN_VALUE', 20);`}</inlineCode></p>
    <p>{`This signifies that reservations with more than 20 travellers are handled early.`}</p>
    <h4 {...{
      "id": "sorting-criterion---number-of-compartments"
    }}>{`Sorting criterion - Number of compartments`}</h4>
    <p>{`Reservations with a large number of compartments may be handled early in the process. The minimum size for this criterion to be used is defined by this record:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Compartments.MIN_VALUE', 2);`}</inlineCode></p>
    <p>{`This signifies that reservations with more than 2 compartments are handled early.`}</p>
    <h3 {...{
      "id": "defining-the-order-of-the-sorting-criteria"
    }}>{`Defining the order of the sorting criteria`}</h3>
    <p>{`The order used when sorting is defined by records in Sorting_Property. Priorities are set from 0 to 4. 0 is the highest priority.
If no priority is defined for a criterion, this criterion is ignored.`}</p>
    <p>{`When two reservations are compared, the criteria with highest priority are compared. If they are unequal, the comparison is finished. If not, the criteria with second highest oriority are compared.
If they are unequal, the comparison is finished. If not, the next criteria are compared. This continues until all priorities have been checked.`}</p>
    <p>{`The criterion 'Number of travellers' is given priority 0 - highest:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Priority.TRAVELLERS', 0));`}</inlineCode></p>
    <p>{`The criterion 'Number of compartments' is given priority 1:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Priority.COMPARTMENTS', 1));`}</inlineCode></p>
    <p>{`The criterion 'Critical seating properties' is given priority 2:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Priority.CRITICAL_PROPERTIES', 2));`}</inlineCode></p>
    <p>{`The criterion 'Important seating properties' is given priority 3:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Priority.IMPORTANT_PROPERTIES', 3));`}</inlineCode></p>
    <p>{`The criterion 'Less important seating properties' is given priority 4 - lowest:`}</p>
    <p><inlineCode parentName="p">{`INSERT INTO sorting_property(sorting_key, sorting_value) VALUES('Priority.LESS_IMPORTANT_PROPERTIES', 4));`}</inlineCode></p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`The Seating manager requires a Bearer Token in the Authorization header created by the Entur Authentication server to be passed for every request.`}</p>
    <h1 {...{
      "id": "seating-manager-api-reference"
    }}>{`Seating manager API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/seating-manager/v3/api-docs"
      }}>{`See the API reference on Swagger Petstore`}</a></p>
    <Swagger url="https://api.staging.entur.io/seating-manager/v3/api-docs" fallback="https://petstore.swagger.io/?url=https://api.staging.entur.io/seating-manager/v3/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      